
import Component from 'vue-class-component';
import { LoggedInComponentBase } from '../base/loggedInComponentBase';
import { UserModule } from '@/store';
import { getModule } from 'vuex-module-decorators';
import { UserServiceClient } from '@/api/clients/userServiceClient';
import { ValidationRules } from '@/modules/public/models/validationRules';

const userModule = getModule(UserModule);
const userServiceClient = new UserServiceClient();

@Component
export default class ChangePasswordModal extends LoggedInComponentBase {
  public existingPassword: string = '';
  public newPassword: string = '';
  public confirmPassword: string = '';
  public rules: ValidationRules = new ValidationRules();

  // Notification
  public showNotification: boolean = false;
  public notificationMessage: string = '';
  public notificationColour: string = 'green';
  public notificationTimeout: string = '2500';

  public dialogClosed() {
    if (this.$refs.changePasswordForm instanceof HTMLFormElement) {
      this.$refs.changePasswordForm.reset();
    }
  }

  public get passwordConfirmationRule() {
    return () =>
      this.newPassword === this.confirmPassword || 'Passwords must match';
  }

  public get dialog(): boolean {
    return userModule.changePasswordModalVisible;
  }

  public set dialog(value) {
    userModule.showChangePasswordModal(value);
  }

  async formSubmit(form: VForm) {
    try {
      // Reset time to show notification
      this.notificationTimeout = '2500';
      this.showNotification = false;

      if (form.validate()) {
        await userServiceClient.updateProfile({
          oldPassword: this.existingPassword,
          newPassword: this.newPassword
        });

        this.notificationMessage = 'Your password has been updated.';
        this.notificationColour = 'success';
        this.showNotification = true;

        setTimeout(() => {
          this.userModule.showChangePasswordModal(false);
        }, parseInt(this.notificationTimeout));
      }
    } catch (err) {
      if (
        err.response &&
        err.response.data &&
        err.response.data.message !== ''
      ) {
        this.notificationMessage = err.response.data.message;
      } else {
        this.notificationMessage = 'There was an error updating your password.';
      }

      this.notificationTimeout = '10000';
      this.notificationColour = 'error';
      this.showNotification = true;
    }
  }
}
